<template>
<iframe name="server_page" 
  :src="path"
  :height="height"
  :class="[toogleIframeIndex]"
   contenteditable="true" width="98%"  frameborder="0"
    scrolling="no" marginheight="0" marginwidth="0" referrerpolicy="unsafe-url" allowfullscreen="true"
    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation"></iframe>
</template>

<script>
export default {
  name: "LoadPage",
  data() {
    return {
      path: "",
      token: `${window.sessionStorage.token}`.replace(/\"/g, ""),
      height: $(window).height()-65,
      IframeIsOpen:0
    };
  },
  methods: {
    receiveMessageFromFrame(event) {
      console.log("Recive from iframe", event);
      if(event.data && event.data.toogleIframeIndex!==undefined ){this.IframeIsOpen = event.data.toogleIframeIndex;}
      if (event.data && event.data.contentHeight) {
        this.height = event.data.contentHeight>$(window).height()-65?event.data.contentHeight:$(window).height()-65;
      }
      if(this.height < $(window).height() - 80) {
        this.height = $(window).height() - 80;
      }
      if (event.data && event.data.scrollHeight !== undefined) {
        $(window).scrollTop(event.data.scrollHeight);
      }
    },
    sendScrollHeightToFrame() {
      $("iframe[name=server_page]")[0].contentWindow.postMessage({ scrollHeight: $(window).scrollTop() }, '*');
    }
  },
  computed: {
    toogleIframeIndex() {
      return this.IframeIsOpen?'toogleIframe':'';
    }
  },
  mounted() {
    this.path = window.axios.defaults.baseURL + "Pages/" + this.$route.query._ + ".aspx?token=" + this.token;
    window.removeEventListener("message", this.receiveMessageFromFrame);
    window.addEventListener("message", this.receiveMessageFromFrame, false);
    // if($('iframe[name=server_page]')){
    //   $(window).scroll(function(){
    //       $('iframe[name=server_page]')[0].contentWindow.postMessage({ scrollHeight: $(window).scrollTop() }, '*');
    //   });
    // }
  },
  updated(){
    console.log(this.$route, this.token);
  }
};
</script>
<style lang="scss" scoped>
  .toogleIframe{
    position:fixed;
    left:0;
    top:0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background: transparent;
  }
</style>